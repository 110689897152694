import {
  Box,
  Image,
  useBreakpointValue,
  Text,
  Heading,
  Center,
  Button,
  Flex,
  Spinner,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import "./grid-component.css";
const bgImage =
  " https://images.unsplash.com/photo-1495822892661-2ead864e1c7b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

const GridComponent = ({
  images,
  titleHeading,
  descriptionHeading,
  titleHeading1,
  descriptionHeading1,
  titleHeading2,
  descriptionHeading2,
  titleHeading3,
  descriptionHeading3,
  titleHeading4,
  descriptionHeading4,
  titleHeading5,
  descriptionHeading5,
}) => {
  const [recommendedPosts, setRecommendedPosts] = useState([]);

  const getRandomRecommendedPosts = (data, count) => {
    const shuffled = data.slice().sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  useEffect(() => {
    // Simulate fetching your JSON data (replace with actual fetch call)
    const fetchJsonRandomData = async () => {
      // Replace this with your actual API endpoint or data source
      const response = await fetch("/mydata.json");
      const data = await response.json();
      setRecommendedPosts(data.informatiiutile);
    };

    fetchJsonRandomData();
  }, []); // The empty dependency array ensures the effect runs once

  const history = useNavigate();

  const handleButtonClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Navigate to the '/vacante' route
    history.push("/vacante");
  };

  const columns = useBreakpointValue({ base: 1, md: 2 });
  const widthResponsive = useBreakpointValue({ base: "100%", md: "75%" });
  const widthResponsiveHeading = useBreakpointValue({
    base: "100%",
    md: "75%",
    lg: "50%",
  });
  const heightGrid = useBreakpointValue({
    base: "250px",
    lg: "300px",
  });
  const widthGrid = useBreakpointValue({
    base: "95%",
    lg: "75%",
  });

  const fontSizeTitleFilter = useBreakpointValue({ base: "2xl", lg: "6xl" });

  const fontSizeTitlePageWidth = useBreakpointValue({
    base: "100%",
    lg: "50%",
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const ExternalLink = ({ to, children }) => (
    <Link
      to={to}
      style={{ color: "inherit", textDecoration: "inherit" }}
      onClick={scrollToTop} // Optionally scroll to top when the link is clicked
    >
      {children}
    </Link>
  );

  return (
    <>
      <Box
        bgImage={`url(${bgImage})`}
        bgSize="cover" // Adjust the background size as needed
        bgRepeat="no-repeat" // Set the background repeat
        bgPosition="center" // Set the background position
        p="100px" // Add padding if needed
        textAlign="center" // Center the content horizontally
      >
        <ExternalLink to="https://vacante.travel-time.ro/">
          <Button
            colorScheme="teal"
            align="center"
            textAlign="center"
            type="submit"
            mt="10px"
            size="lg"
          >
            Rezerva-ti vacanta preferata!
          </Button>
        </ExternalLink>
      </Box>

      <Box
        borderColor="gray.100"
        borderWidth="2px"
        mt="10px"
        width={widthResponsive}
        mx="auto"
      >
        <Box width="100%" mx="auto">
          <Box position="relative" paddingBottom={"50%"}>
            <Image
              src={images[0].img}
              alt={images[0].alt}
              width="100%"
              height="100%"
              objectFit="cover"
              position="absolute"
              top="0"
              left="0"
              transform="scaleY(1)"
              transformOrigin="top"
            />
          </Box>
        </Box>

        <Box
          className="heading"
          width="100%"
          mx="auto"
          justifyContent="center"
          alignItems="center"
          p={4}
        >
          <Heading
            width={widthResponsiveHeading}
            mx="auto"
            background="teal"
            color="white"
            mt="7px"
            textAlign="center"
            size="lg"
            as="h1"
            mb={4}
          >
            {titleHeading}
          </Heading>
          <Text fontSize="xl" mb={6}>
            {descriptionHeading}
          </Text>
          <Heading as="h2" size="lg" mb={4}>
            {titleHeading1}
          </Heading>
          <Text fontSize="md" mb={6}>
            {descriptionHeading1}
          </Text>
          <Heading as="h3" size="lg" mb={4}>
            {titleHeading2}
          </Heading>
          <Text fontSize="md" mb={6}>
            {descriptionHeading2}
          </Text>
          <Heading as="h4" size="lg" mb={4}>
            {titleHeading3}
          </Heading>
          <Text fontSize="md" mb={6}>
            {descriptionHeading3}
          </Text>
          <Heading as="h5" size="lg" mb={4}>
            {titleHeading4}
          </Heading>
          <Text fontSize="md" mb={6}>
            {descriptionHeading4}
          </Text>
          <Heading as="h6" size="lg" mb={4}>
            {titleHeading5}
          </Heading>
          <Text fontSize="md" mb={6}>
            {descriptionHeading5}
          </Text>
        </Box>
        <Box p={4}>
          <Heading
            width={widthResponsiveHeading}
            mx="auto"
            textAlign="center"
            size="md"
            as="h2"
            color="teal"
          >
            Pentru o oferta personalizata:
          </Heading>
          <Link to="/contact">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                colorScheme="teal"
                align="center"
                type="submit"
                mt="10px"
                size="lg"
                _hover={{ bg: "rgba(255, 0, 0, 0.7)" }}
              >
                Contacteaza-ne!
              </Button>
            </div>
          </Link>
        </Box>
      </Box>

      <Box
        bgImage={`url(${bgImage})`}
        bgSize="cover" // Adjust the background size as needed
        bgRepeat="no-repeat" // Set the background repeat
        bgPosition="center" // Set the background position
        p="100px" // Add padding if needed
        textAlign="center" // Center the content horizontally
      >
        <ExternalLink to="https://vacante.travel-time.ro/">
          <Button
            colorScheme="teal"
            align="center"
            textAlign="center"
            type="submit"
            mt="10px"
            size="lg"
          >
            Rezerva-ti vacanta preferata!
          </Button>
        </ExternalLink>
      </Box>

      <Center mt="10">
        <Heading>- Postari Recomandate -</Heading>
      </Center>

      {/* Render the recommended posts */}
      {recommendedPosts ? (
        <Flex mt="15px" flexWrap="wrap" justifyContent="center">
          <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={3} w="100%">
            {getRandomRecommendedPosts(recommendedPosts, 6).map(
              (post, index) => {
                // Define albumHref here for each post
                const albumHref = "/" + post.id + "/";

                return (
                  <Box mb={6}>
                    <GridItem position="relative" width={widthGrid} mx="auto">
                      <Box
                        className="description-title"
                        mx="auto"
                        p={2}
                        mb={3}
                        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                        display="flex"
                        justifyContent="center"
                        background="teal"
                        color="white"
                        fontWeight="bold"
                        fontSize="1.2rem"
                      >
                        {post.description}
                      </Box>
                      <a href={albumHref}>
                        <Image
                          src={post.thumbnail}
                          alt={post.alt}
                          w="100%"
                          h={heightGrid}
                          objectFit="cover"
                        />
                      </a>
                      <a href={albumHref}>
                        <Button
                          position="absolute"
                          bottom={0}
                          right={0}
                          size="sm"
                          colorScheme="black"
                          p={3}
                          mb={3}
                          mr={3}
                          fontSize="1.2rem"
                          type="button"
                          border="1px"
                          _hover={{ bg: "white", color: "black" }}
                        >
                          Mai multe informatii
                        </Button>
                      </a>
                    </GridItem>
                  </Box>
                );
              }
            )}
            ;
          </Grid>
          <Link to="/blog">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleButtonClick}
                colorScheme="teal"
                align="center"
                type="submit"
                mt="10px"
                size="lg"
                _hover={{ bg: "rgba(255, 0, 0, 0.7)" }}
              >
                Revino la Blog
              </Button>
            </div>
          </Link>
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      )}

      <Box
        bgImage={`url(${bgImage})`}
        bgSize="cover" // Adjust the background size as needed
        bgRepeat="no-repeat" // Set the background repeat
        bgPosition="center" // Set the background position
        p="100px" // Add padding if needed
        textAlign="center" // Center the content horizontally
        mt="10"
      >
        <ExternalLink to="https://vacante.travel-time.ro/">
          <Button
            colorScheme="teal"
            align="center"
            textAlign="center"
            type="submit"
            mt="10px"
            size="lg"
          >
            Rezerva-ti vacanta preferata!
          </Button>
        </ExternalLink>
      </Box>
    </>
  );
};

export default GridComponent;
