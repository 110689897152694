import React from "react";
import {
  Box,
  Center,
  Button,
  ChakraProvider,
  CSSReset,
  extendTheme,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel-corporate.css";

function CarouselHomePage() {
  const widthResponsive = useBreakpointValue({
    base: "100%",
    lg: "75%",
  });

  const carouselStyle = {
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: "25px",
  };

  const textResponsive = useBreakpointValue({
    base: "20px",
    md: "30px",
    lg: "45px",
  });

  const legendFontSize = useBreakpointValue({ base: "sm", md: "md", lg: "lg" });
  return (
    <>
      <ChakraProvider>
        <CSSReset />
        <Box style={carouselStyle} width={widthResponsive}>
          <Carousel
            showThumbs={false}
            swipeable={true}
            infiniteLoop={true}
            autoPlay={true}
            stopOnHover={true}
            dynamicHeight={false}
            useKeyboardArrows={true}
          >
            <div>
              <img src="/images/af-kl/KL_PC.jpg" alt="AF-KL-" />
              <Button
                className="legend"
                as="a"
                href="https://www.klm.ro/information/travel-class-extra-options/premium-comfort-class"
                size="sm"
                mb={20}
                target="a_blank"
              >
                Descoperă KLM Premium Comfort!
              </Button>
              <p className="legend">
                Confort sporit: Scaune ergonomice, înclinare generoasă, suport
                lombar reglabil.
              </p>
            </div>
            <div>
              <img
                src="/images/af-kl/KL_PC_2.jpg"
                alt="Servicii de trasport aerian - Corporate"
              />
              <Button
                className="legend"
                as="a"
                href="https://www.klm.ro/information/travel-class-extra-options/premium-comfort-class"
                size="sm"
                mb={20}
                target="a_blank"
              >
                Descoperă KLM Premium Comfort!
              </Button>
              <p className="legend">
                Deliciu culinar: Savurează o masă rafinată cu un meniu atent
                selecționat.
              </p>
            </div>
            <div>
              <img
                src="/images/af-kl/KL_PC_3.jpg"
                alt="Servicii de trasport aerian - Corporate"
              />
              <Button
                className="legend"
                as="a"
                href="https://www.klm.ro/information/travel-class-extra-options/premium-comfort-class"
                size="sm"
                mb={20}
                target="a_blank"
              >
                Descoperă KLM Premium Comfort!
              </Button>
              <p className="legend">
                Spațiu generos, zbor relaxant: Descoperă scaunele spațioase cu
                Premium Comfort.
              </p>
            </div>
            <div>
              <img
                src="/images/af-kl/KL_PC_boeing.jpg"
                alt="Servicii de trasport aerian - Corporate"
              />
              <Button
                className="legend"
                as="a"
                href="https://www.klm.ro/information/travel-class-extra-options/premium-comfort-class"
                size="sm"
                mb={20}
                target="a_blank"
              >
                Descoperă KLM Premium Comfort!
              </Button>
              <p className="legend">
                Călătorie plăcută, experiență memorabilă.
              </p>
            </div>
          </Carousel>
        </Box>
      </ChakraProvider>

      <ChakraProvider>
        <CSSReset />
        <Box style={carouselStyle} width={widthResponsive}>
          <Carousel
            showThumbs={false}
            swipeable={true}
            infiniteLoop={true}
            autoPlay={true}
            stopOnHover={true}
            dynamicHeight={false}
            useKeyboardArrows={true}
          >
            <div>
              <img src="/images/af-kl/777-48J-PE.jpg" alt="AF - La Premier" />
              <Button
                className="legend"
                as="a"
                href="https://wwws.airfrance.ro/information/prepare/voyager-en-premium-economy"
                size="sm"
                mb={20}
                target="a_blank"
              >
                Descoperă Air France Premium Economy!
              </Button>
              <p className="legend">
                Relaxează-te și bucură-te de zbor cu spațiul suplimentar alături
                de Air France!
              </p>
            </div>
            <div>
              <img src="/images/af-kl/777-48J-PE-2.jpg" alt="AF- Business" />
              <Button
                className="legend"
                as="a"
                href="https://wwws.airfrance.ro/information/prepare/voyager-en-premium-economy"
                size="sm"
                mb={20}
                target="a_blank"
              >
                Descoperă Air France Premium Economy!
              </Button>
              <p className="legend">
                Descoperă un spațiu dedicat, intim si armonios în cabina Premium
                Economy.
              </p>
            </div>
            <div>
              <img
                src="/images/af-kl/777-48J-PE-3.jpg"
                alt="AF - PREMIUM ECONNOMY "
              />
              <Button
                className="legend"
                as="a"
                href="https://wwws.airfrance.ro/information/prepare/voyager-en-premium-economy"
                size="sm"
                mb={20}
                target="a_blank"
              >
                Descoperă Air France Premium Economy!
              </Button>
              <p className="legend">
                Liniște, pace si spațiu suplimentar... Călătorește confortabil
                în propria ta oază personală.
              </p>
            </div>
            <div>
              <img
                src="/images/af-kl/A350-34J-PE-4.jpg"
                alt="AF - PREMIUM ECONNOMY "
              />
              <Button
                className="legend"
                as="a"
                href="https://wwws.airfrance.ro/information/prepare/voyager-en-premium-economy"
                size="sm"
                mb={20}
                target="a_blank"
              >
                Descoperă Air France Premium Economy!
              </Button>
              <p className="legend">
                Descoperă o lume de divertisment la bordul Air France Premium
                Economy
              </p>
            </div>
            <div>
              <img
                src="/images/af-kl/Premium Economy Main Meal.jpg"
                alt="AF - PREMIUM ECONNOMY "
              />
              <Button
                className="legend"
                as="a"
                href="https://wwws.airfrance.ro/information/prepare/voyager-en-premium-economy"
                size="sm"
                mb={20}
                target="a_blank"
              >
                Descoperă Air France Premium Economy!
              </Button>
              <p className="legend">
                O experiența culinară delicioasa la bord!
              </p>
            </div>
          </Carousel>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default CarouselHomePage;
